import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {
	Avatar,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
} from "@mui/material";
import React, {ForwardedRef, forwardRef, Fragment, useState} from "react";
import {Location, LocationAncestry, LocationTree} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationIcon} from "./LocationIcon";

export interface LocationTreeViewProps {
	locations: LocationTree[];
	selected?: LocationTree | Location | LocationAncestry;
	onSelect: (location: LocationTree) => void;
	loading?: boolean;
}

interface LocationTreeItemProps {
	location: LocationTree;
	collapsed: number[];
	selected?: LocationTree | Location;
	onSelect: (location: LocationTree) => void;
	onCollapseToggle: (locationId: number) => void;
}

export const LocationTreeView = forwardRef(
	(props: LocationTreeViewProps, ref: ForwardedRef<HTMLUListElement>) => {
		const {locations, selected, onSelect, loading} = props;
		const [collapsed, setCollapsed] = useState<number[]>([]);

		const toggleCollapse = (locationId: number) => {
			if (collapsed.includes(locationId)) {
				setCollapsed(prev => prev.filter(id => id !== locationId));
			}
			else {
				setCollapsed(prev => [...prev, locationId]);
			}
		};

		return (
			<List dense sx={{opacity: loading ? 0.5 : 1}} ref={ref}>
				{locations?.map(location => (
					<LocationTreeItem
						key={location.id}
						location={location}
						selected={selected}
						onCollapseToggle={toggleCollapse}
						onSelect={onSelect}
						collapsed={collapsed}
					/>
				))}
			</List>
		);
	},
);

function LocationTreeItem({
	location,
	collapsed,
	selected,
	onSelect,
	onCollapseToggle,
}: LocationTreeItemProps) {
	const {t} = useLocalization();
	const hasChildren = location.children.length > 0;
	const isCollapsed = collapsed.includes(location.id);

	const handleCollapseToggle = (event: React.MouseEvent) => {
		event.stopPropagation();
		onCollapseToggle(location.id);
	};

	return (
		<Fragment>
			<ListItem
				secondaryAction={
					<IconButton onClick={handleCollapseToggle}>
						{hasChildren
							&& (isCollapsed
								? <ExpandLess />
								: <ExpandMore />)}
					</IconButton>
				}
				disablePadding
			>
				<ListItemButton
					onClick={() => onSelect(location)}
					selected={selected == location}
					sx={{opacity: location.enabled ? 1 : 0.5}}
				>
					<ListItemAvatar>
						<Avatar>
							<LocationIcon type={location.type} />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={location.name}
						secondary={location.type && t(`locations.types.${location.type}`)}
					/>
				</ListItemButton>
			</ListItem>
			{hasChildren && (
				<Collapse in={isCollapsed}>
					<List dense sx={{pl: 3}}>
						{location.children.map(childLocation => (
							<LocationTreeItem
								key={childLocation.id}
								location={childLocation}
								selected={selected}
								onCollapseToggle={onCollapseToggle}
								onSelect={onSelect}
								collapsed={collapsed}
							/>
						))}
					</List>
				</Collapse>
			)}
		</Fragment>
	);
}
