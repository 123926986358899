import {
	Business,
	Dashboard,
	Face,
	Group,
	Inventory2,
	LocationCity,
	Schedule,
	Security,
	SupervisedUserCircle,
	VerifiedUser,
	ViewColumn,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider as MuiThemeProvider} from "@mui/material";
import {JetbrainsMonoFont} from "@variocube/app-ui/src/VCThemeProvider/JetbrainsMonoFont";
import {RobotoFont} from "@variocube/app-ui/src/VCThemeProvider/RobotoFont";
import React, {PropsWithChildren} from "react";

export const LocationIcon = LocationCity;
export const BookingIcon = Schedule;
export const LockerIcon = ViewColumn;
export const UsersIcon = SupervisedUserCircle;
export const UserIcon = Face;
export const SuperuserIcon = VerifiedUser;
export const TenantIcon = Business;
export const CompartmentIcon = Inventory2;
export const GroupIcon = Group;
export const RoleIcon = Security;
export const DashboardIcon = Dashboard;
export const LockScanIcon = LockIcon;

export function ThemeProvider({children}: PropsWithChildren<{}>) {
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<RobotoFont />
			<JetbrainsMonoFont />
			{children}
		</MuiThemeProvider>
	);
}

const theme = responsiveFontSizes(createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#FFED00",
		},
		secondary: {
			main: "#ccc452",
		},
		error: {
			main: "#e62e2e",
		},
		success: {
			main: "#43a843",
		},
		warning: {
			main: "#e68739",
		},
		info: {
			main: "#294fcc",
		},
		text: {
			primary: "#ffffff",
		},
		background: {
			default: "#0f0f08",
			paper: "#1f1f17",
		},
	},
	typography: {
		h1: {
			fontSize: 60,
			fontWeight: 300,
		},
		h2: {
			fontSize: 36,
			fontWeight: 500,
		},
		h3: {
			fontSize: 28,
			fontWeight: 500,
		},
		h4: {
			fontSize: 24,
			fontWeight: 500,
		},
		h5: {
			fontSize: 20,
			fontWeight: 500,
		},
		h6: {
			fontSize: 18,
			fontWeight: 500,
		},
		overline: {
			fontWeight: 500,
		},
	},
	components: {
		MuiLink: {
			defaultProps: {
				underline: "hover",
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "unset",
				},
			},
		},
	},
}));
